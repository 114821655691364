import { Outlet, useLocation } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import GredientSVG from "./GredientSVG";
import { useEffect } from 'react';
import { Container, Image, Navbar } from "react-bootstrap";

const MainWrapper = () => {
  const location = useLocation();
  const isHomepage = location.pathname === '/';
  const isThankyou = location.pathname === '/thank-you';
  const admin = location.pathname === '/admin/list';
  const isHavsogaHomePage1 = location.pathname === '/havsoga';
  const isHavsogaHomePage2 = location.pathname === '/havsoga-old';
  const isHavsogaPage1 = location.pathname === '/havsoga-registrerad';
  const isHavsogaPage2 = location.pathname === '/havsoga-player';
  const isStudio = location.pathname.includes("studio");
  const isSport = location.pathname.includes("sport");
  const isRealEstate = location.pathname.includes("fastighet");
  const isIndividual = location.pathname.includes('individual');
  const isSocialamedier = location.pathname === '/socialamedier';
  const iswebblabbet = location.pathname.includes('webblabbet');

  useEffect(() => {
    if (isHavsogaHomePage2 || isHavsogaPage1 || isHavsogaPage2 || isHavsogaHomePage1 || isIndividual) {
      document.body.classList.add('bg-black');
    } else {
      document.body.classList.remove('bg-black');
    }
  }, [isHavsogaHomePage2 || isHavsogaPage1 || isHavsogaPage2 || isHavsogaHomePage1 || isIndividual]);

  useEffect(() => {
    const isRealEstate = location.pathname === '/real-estate';
    if (isStudio || isSport || isRealEstate) {
      document.body.classList.add('bg-black-gray');
    } else {
      document.body.classList.remove('bg-black-gray');
    }
  }, [isStudio, isSport, isRealEstate]);

  return (

    <div>
      {/* {isHavsogaHomePage2 || isHavsogaPage1 || isHavsogaPage2 || isHavsogaHomePage1 ? (
                <Outlet />
            ) : (
                // <div className={`bg-gradientblock pt-md-30 pt-6 ${admin || isStudio ? 'studio-page' : ''} ${admin || isStudio || isSport || isHomepage ? '' : 'homepage'} ${isHomepage ? 'homepage home-active' : ''} ${isSport ? 'sport-page' : ''}`}> */}
      <div className={`bg-gradientblock pt-md-30 pt-6 ${admin || isStudio ? 'studio-page' : ''
        } ${admin || isStudio || isSport || isHomepage || isIndividual || isSocialamedier || isHavsogaHomePage1 || iswebblabbet ? '' : 'homepage'
        } ${isHomepage ? 'homepage home-active' : ''
        } ${isSport ? 'sport-page' : ''
        } ${isRealEstate ? 'real-estate-page' : ''
        } ${isIndividual ? 'individual-page' : ''
        } ${isHavsogaHomePage1 ? 'havasoga-page' : ''
        } ${iswebblabbet ? 'webblabbet-page' : ''
        } ${isSocialamedier ? 'socialmedia-page' : ''
        }`}
      >
        {/* Render the Header or only the logo on the webblabbet page */}
        {!iswebblabbet ? (
          <Header toggle={isThankyou ? false : true} />
        ) : (
          <section className="onlylogo">
            <Navbar className="bg-transparent text-white">
              <Container>
                <div className="position-relative w-100 d-flex align-items-center">
                  <Image src="/assets/images/webblabbet-logo.svg" alt="Fillabbet" />
                </div>
              </Container>
            </Navbar>
          </section>
        )}

        <div className={`pt-md-103  ${isThankyou ? 'pt-30' : 'pt-45'} ${isHavsogaHomePage1 ? 'havasoga-padding' : 'pt-45'}`}>
          <Outlet></Outlet>
        </div>
        <Footer />
      </div>

      {/* )} */}
    </div >
  )
}

export default MainWrapper;