import { useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { Button, Container, Image, Nav, NavDropdown, Navbar, Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";

const Header = (toggle = true) => {
    const [show, setShow] = useState(false);
    const location = useLocation();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(!show);
    const navbarTogglerRef = useRef(null);

    const handleClick = () => {
        if (navbarTogglerRef.current) {
            navbarTogglerRef.current.click();
        }
    };

    const triggerNavbarTogglerClick = () => {
        handleClick();
    };

    return (
        <Navbar expand="lg" className="bg-transparent text-white">
            <Container>
                <div className={`position-relative w-100 d-flex align-items-center ${!toggle.toggle && 'text-md-left text-center mobile-center'}`}>

                    <Link to="/" className={`navbar-brand  p-0 d-lg-none d-block`}><Image src="/assets/images/logo.svg" alt="Fillabbet" /></Link>
                    {toggle.toggle && <Navbar.Toggle ref={navbarTogglerRef} aria-controls="basic-navbar-nav" className="d-lg-none" onClick={handleShow} />}

                    <Offcanvas show={show} onHide={handleClose} responsive="lg" className="w-100 d-flex align-items-center justify-content-between">
                    <Link to="/" className={`navbar-brand  p-0 flex-shrink-0 d-lg-block d-none`} ><Image src="/assets/images/logo.svg" alt="Fillabbet" /></Link>
                        <Nav className="me-auto justify-content-center w-100 ">
                            <Link to="/portfolio" className={`text-white fw-light font-18 nav-link  px-0 ${location.pathname.includes("portfolio") ? 'active_color' : ''} ` } onClick={triggerNavbarTogglerClick}>Portfolio</Link>
                            {/* <Link to="/socialamedier" className="text-white fw-light font-18 nav-link me-lg-30 px-0" onClick={triggerNavbarTogglerClick}>Sociala medier</Link> */}
                            {/* <Link to="/studio" className="text-white fw-light font-18 nav-link px-0" onClick={triggerNavbarTogglerClick}>Studios</Link> */}
                            {/* <Nav.Link href="/package" className="text-white fw-light font-18">Paket</Nav.Link> */}
                           
                            <Link to="/om-oss" className="text-white fw-light font-18 nav-link px-0 text-nowrap d-lg-none d-block mt-80" onClick={triggerNavbarTogglerClick}>Om oss</Link>
                            <Link to="/kontakt" className="text-white fw-light font-18 nav-link px-0 d-lg-none d-block" onClick={triggerNavbarTogglerClick}>kontakt</Link>
                        </Nav>
                        <div className="align-items-center d-lg-flex d-none">
                            <Link to="/om-oss"  className={`text-white fw-light font-18 nav-link px-0 me-4 text-nowrap ${location.pathname.includes("om-oss") ? 'active_color' : ''} ` } onClick={triggerNavbarTogglerClick}>Om oss</Link>
                            <Link to="/kontakt"  className={`text-white fw-light font-18 nav-link px-0  text-nowrap ${location.pathname.includes("kontakt") ? 'active_color' : ''} ` } onClick={triggerNavbarTogglerClick}>Kontakt</Link>
                        </div>
                        
                    </Offcanvas>
                </div>
            </Container>
        </Navbar>
    )
}

export default Header;