import { Button, Dropdown } from "react-bootstrap";
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect,useState } from "react";
import { getCources } from '../store/api/course.api';


const ComonDropDown = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedIndex,setSelectedIndex] = useState(localStorage.getItem("lastIndex")?localStorage.getItem("lastIndex"):0)
    const gotoPage = (id,pagaName,index) => {
        localStorage.setItem("lastIndex",index)
        setSelectedIndex(localStorage.getItem("lastIndex")?localStorage.getItem("lastIndex"):0);
        navigate('/' + id+'/'+ pagaName);
    }
    const [allCources, setAllCources] = useState();
    useEffect(() => {
        refresh();
    }, []);
    const refresh = () => {
        
          dispatch(getCources()).then((course) => {
              setAllCources(course.payload?.data);
          });
      }
    return (
        <>
            {/* <Dropdown className="portfolio-dropdown">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {localStorage.getItem("catName")}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => gotoPage('portfolio')}>Narrative</Dropdown.Item>
                    <Dropdown.Item onClick={() => gotoPage('sport')}>Sport</Dropdown.Item>
                    <Dropdown.Item onClick={() => gotoPage('real-estate')}>Fastighet</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown> */}
                <div className="portfolio-buttons-selection">
                {
                    
                allCources && allCources.length > 0 && allCources?.map((item, index) => (
                    <Button  className={`${index == selectedIndex ? 'active' : ''}`}  varient="white" onClick={()=>gotoPage(item.id,item.slug,index)}>{item.category_name}</Button>
                ))
                }
                </div>
        </>
    )
}

export default ComonDropDown;