import { useState, useEffect } from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import FAQ from "../component/FAQ";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { contactUs } from '../store/api/course.api'
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";


const Contact = () => {

    const [value, setValue] = useState({ email: "" });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let require = []
    const onChange = (e) => {
        setValue({
            ...value,
            [e.target.name]: e.target.value,
        });
    };
    useEffect(() => {
        // Scroll the HTML and body elements to the top
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
    }, []);
    const oncheckboxchange = (e) => {
        if (e.target.checked && !require.includes(e.target.value)) {
            require.push(e.target.value)
        } else {
            let index = require.indexOf(e.target.value); // 1
            console.log(index)
            require.splice(index, 1)

        }
        console.log(require)
    }
    const onAdd = () => {
        let data = {
            email: value.email,
            phone: value?.phone,
            message: value?.message,
            name: value?.name,
            business_name: value?.business_name,
            require_service: require.toString(),
            from_studio: false
        }
        dispatch(contactUs(data))
            .unwrap()
            .then((res) => {
                console.log(res.status)
                if (res.status === 200) {
                    navigate('/thank-you');
                }

            })
            .catch(() => {

            });
    };
    const openCal = () => {
        window.open('https://cal.com/filmlabbet', '_blank')
    }
    const settings1 = {
        dots: false,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centermode: true,
        centerPadding: "0px",
        autoplay: true,
    };
    return (
        <>
            <div className="pb-md-120 pb-11 mt-neg40">
                <Container>
                    {/* <Link to="/" className="btn btn-link btn-back p-0 mb-9 d-md-none" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="11" viewBox="0 0 17 11" fill="none">
                        <path d="M1.22504 5.45014L0.799295 5.0244L0.373554 5.45014L0.799295 5.87588L1.22504 5.45014ZM15.6752 6.05223C16.0077 6.05223 16.2773 5.78266 16.2773 5.45014C16.2773 5.11761 16.0077 4.84805 15.6752 4.84805V6.05223ZM5.61601 0.207681L0.799295 5.0244L1.65078 5.87588L6.46749 1.05916L5.61601 0.207681ZM0.799295 5.87588L5.61601 10.6926L6.46749 9.84111L1.65078 5.0244L0.799295 5.87588ZM1.22504 6.05223H15.6752V4.84805H1.22504V6.05223Z" fill="#DCE1DE"/>
                    </svg>
                </Link> */}
                    <div className="block font-18 fw-light mb-28 text-center mb-40">
                        <h1 className="font-56 font-secondary">Let's get to work</h1>
                        <p>Välj att boka möte eller bli kontaktad</p>
                    </div>
                    <div className="blockwrap maxw-575 mx-auto text-center px-md-4 px-3 py-md-9 py-5 mb-9">
                        <div className="font-18 fw-light text-center letter-xs block mb-5">
                            <h2 className="fs-24 letter-sm mobile-font18">Boka möte</h2>
                            <p className="m-0">Klicka på knappen nedan för att boka ett möte med oss</p>
                        </div>
                        <Button variant="primary" type="button" onClick={openCal}>
                            Boka möte
                        </Button>
                    </div>

                    <div className="maxw-745 mx-auto mb-9 px-8">
                        <div className="border-top"></div>
                    </div>


                </Container>
                <div className="contact-blockwrap position-relative">
                    <Row>
                        <Col md={6}>
                            <Form className="d-flex flex-column gap-20 mb-9">
                                <Row>
                                    <Col lg={12}>
                                        <div className="font-18 fw-light mb-32 letter-xs block mb-30">
                                            <p>Använd formuläret för att komma i kontakt med oss. </p>
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Group controlId="inputName">
                                            <Form.Label>* Namn</Form.Label>
                                            <Form.Control type="text" placeholder="Namn" name='name' value={value.name} onChange={onChange} />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Group controlId="inputTel">
                                            <Form.Label>* Telefon</Form.Label>
                                            <Form.Control type="tel" placeholder="Telefon" name='phone' value={value.phone} onChange={onChange} />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Group controlId="inputPost">
                                            <Form.Label>* E-post</Form.Label>
                                            <Form.Control type="email" placeholder="E-post" name='email' value={value.email} onChange={onChange} />
                                        </Form.Group>
                                    </Col>

                                    <Col sm={6}>
                                        <Form.Group>
                                            <Form.Label>* Företag</Form.Label>
                                            <Form.Control type="text" placeholder="Företag" name='business_name' value={value.business_name} onChange={onChange} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12}>
                                        <Form.Group>
                                            <Form.Label>Meddelande</Form.Label>
                                            <Form.Control
                                                className="contact-teaxtarea"
                                                as="textarea"
                                                placeholder="Meddelande"
                                                name="message"
                                                value={value.message} onChange={onChange}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col sm={12} className=" text-md-start text-center">
                                        <Button variant="primary" type="button" onClick={onAdd}>
                                            Skicka
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                        <Col md={6}>
                            <div className="imgwrp">
                                <img src="/assets/images/contactBlockTv.jpg"></img>
                            </div>
                        </Col>
                    </Row>
                </div>

            </div>
            <FAQ />

            <section className="Booky-slider ">
                <Container>
                    <div className="Booky-slider-itembox">
                        <Slider {...settings1}>
                            <div className="Booky-slider-item">
                                <div className="Booky-slider-itemwrap">
                                    <span className="Booky-slider-logo"> booky</span>
                                    <p className="Booky-slider-text">
                                        Vi tog hjälp av Docens för att skapa en intern utbildningsfilm till en av våra kunder. Docens levererade på topp från start till slut. De var med på förmöte, de skötte hela produktionen och inkluderade vår kunds grafiska profil i redigeringen. Funderar ni på att skapa utbildningsmaterial eller utbildningsfilmer så tycker jag verkligen att ni ska kontakta Docens.
                                    </p>
                                    <div className="Booky-slider-profile d-flex align-items-center justify-content-center gap-md-6 gap-2">
                                        <img width={73} height={73} src="/assets/images/Charlotte_Dunhoff.png" alt="Office"
                                            className="rounded-3" />
                                        <div>
                                            <span className="Booky-slider-profileinfo-text1 d-block mb-1">
                                                Charlotte Dunhoff
                                            </span>
                                            <span className="Booky-slider-profileinfo-text2 d-block">
                                                Grundare & VD
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="Booky-slider-item">
                                <div className="Booky-slider-itemwrap">
                                    <span className="Booky-slider-logo"> booky</span>
                                    <p className="Booky-slider-text">
                                        Vi tog hjälp av Docens för att skapa en intern utbildningsfilm till en av våra kunder. Docens levererade på topp från start till slut. De var med på förmöte, de skötte hela produktionen och inkluderade vår kunds grafiska profil i redigeringen. Funderar ni på att skapa utbildningsmaterial eller utbildningsfilmer så tycker jag verkligen att ni ska kontakta Docens.
                                    </p>
                                    <div className="Booky-slider-profile d-flex align-items-center justify-content-center gap-md-6 gap-2">
                                        <img width={73} height={73} src="/assets/images/Charlotte_Dunhoff.png" alt="Office"
                                            className="rounded-3" />
                                        <div>
                                            <span className="Booky-slider-profileinfo-text1 d-block mb-1">
                                                Charlotte Dunhoff
                                            </span>
                                            <span className="Booky-slider-profileinfo-text2 d-block">
                                                Grundare & VD
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                    <div className="Booky-slider-logowrap d-flex justify-content-between flex-wrap gap-8 text-center">
                        <div className="logo-wrap">
                            <Image src="/assets/images/sliderlogo1.png" alt="Spotify" />
                        </div>
                        <div className="logo-wrap">
                            <Image src="/assets/images/sliderlogo2.png" alt="Spotify" />
                        </div>
                        <div className="logo-wrap">
                            <Image src="/assets/images/sliderlogo3.png" alt="Spotify" />
                        </div>
                        <div className="logo-wrap">
                            <Image src="/assets/images/sliderlogo4.png" alt="Spotify" />
                        </div>
                    </div>

                </Container>
            </section>
        </>

    )
}

export default Contact;